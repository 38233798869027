import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Icon,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { ReactElement } from 'react';
import {
  FcCalculator,
  FcDocument,
  FcMoneyTransfer,
  FcHome,
  FcBusinessman,
  FcPortraitMode,
  FcFactory,
  FcGlobe,
} from 'react-icons/fc';
import React from 'react';

interface CardProps {
  heading: string;
  description: string;
  icon: ReactElement;
  href: string;
}

const Card = ({ heading, description, icon, href }: CardProps) => {
  return (
    <Box
      maxW={{ base: 'full', md: '275px' }}
      w={'full'}
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p={5}
    >
      <Stack align={'start'} spacing={2}>
        <Flex
          w={16}
          h={16}
          align={'center'}
          justify={'center'}
          color={'white'}
          rounded={'full'}
          bg={useColorModeValue('gray.100', 'gray.700')}
        >
          {icon}
        </Flex>
        <Box mt={2}>
          <Heading size="md">{heading}</Heading>
          <Text mt={1} fontSize={'sm'}>
            {description}
          </Text>
        </Box>
        <Button variant={'link'} colorScheme={'blue'} size={'sm'}>
          Scopri di più..
        </Button>
      </Stack>
    </Box>
  );
};

export default function Features() {
  return (
    <Box p={4} mt="20">
      <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
        <Heading fontSize={{ base: '2xl', sm: '4xl' }} fontWeight={'bold'}>
          I nostri servizi
        </Heading>
        <Text color={'gray.600'} fontSize={{ base: 'sm', sm: 'lg' }}>
          Siamo un team di commercialisti specializzati in contabilità aziendale
          e dichiarazioni dei redditi, ma offriamo anche servizi di
          finanziamento, tra cui cessioni del quinto e mutui.
        </Text>
      </Stack>

      <Container maxW={'5xl'} mt={12}>
        <Flex flexWrap="wrap" gridGap={6} justify="center">
          <Card
            heading={'Contabilità aziende'}
            icon={<Icon as={FcCalculator} w={10} h={10} />}
            description={
              'Gestiamo la contabilità della tua azienda, dalle scritture contabili alla predisposizione del bilancio.'
            }
            href={'#'}
          />
          <Card
            heading={'Dichiarazione Redditi'}
            icon={<Icon as={FcDocument} w={10} h={10} />}
            description={
              'Ti aiutiamo nella compilazione e presentazione della dichiarazione dei redditi, garantendoti il massimo risparmio fiscale.'
            }
            href={'#'}
          />
          <Card
            heading={'Finanziamenti a privati, a imprese'}
            icon={<Icon as={FcMoneyTransfer} w={10} h={10} />}
            description={
              'Offriamo soluzioni di finanziamento personalizzate per privati e imprese, a tassi competitivi e con tempi di erogazione rapidi.'
            }
            href={'#'}
          />
          <Card
            heading={'Cessioni del quinto, mutui'}
            icon={<Icon as={FcBusinessman} w={10} h={10} />}
            description={
              'Ti offriamo la possibilità di accedere a cessioni del quinto e mutui a condizioni vantaggiose, con un supporto completo nella fase di richiesta.'
            }
            href={'#'}
          />
          <Card
            heading={'Pratiche ipocatastali'}
            icon={<Icon as={FcHome} w={10} h={10} />}
            description={
              "Ci occupiamo di tutte le pratiche ipotecarie, dalla stipula del mutuo all'iscrizione dell'ipoteca, garantendoti un servizio rapido e affidabile."
            }
            href={'#'}
          />

          <Card
            heading={'Trascrizione atti'}
            icon={<Icon as={FcDocument} w={10} h={10} />}
            description={
              'Ci occupiamo di tutte le pratiche di trascrizione di atti immobiliari, garantendoti un servizio preciso e affidabile.'
            }
            href={'#'}
          />
          <Card
            heading={'Successioni'}
            icon={<Icon as={FcPortraitMode} w={10} h={10} />}
            description={
              'Ci occupiamo di tutte le pratiche relative alle successioni, offrendoti un servizio completo e personalizzato.'
            }
            href={'#'}
          />
          <Card
            heading={'Apertura Partite Iva'}
            icon={<Icon as={FcFactory} w={10} h={10} />}
            description={
              "Ci occupiamo di tutta la documentazione necessaria per l'apertura di partite IVA, semplificando le tue pratiche."
            }
            href={'#'}
          />
          <Card
            heading={'Pratiche Suape'}
            icon={<Icon as={FcGlobe} w={10} h={10} />}
            description={
              'Ci occupiamo di tutte le pratiche per la realizzazione di impianti industriali, commerciali e artigianali, garantendo la massima efficienza e competenza.'
            }
            href={'#'}
          />
          <Card
            heading={'Iscrizione CCIAA, Inps, Inail'}
            icon={<Icon as={FcBusinessman} w={10} h={10} />}
            description={
              "Ci occupiamo di tutte le pratiche relative all'iscrizione alla CCIAA, all'INPS e all'INAIL, accompagnandoti in ogni fase."
            }
            href={'#'}
          />
        </Flex>
      </Container>
    </Box>
  );
}
