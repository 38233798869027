import { React, ReactNode } from 'react';

import {
  Box,
  Container,
  Link,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
  Image,
} from '@chakra-ui/react';

import logo from './logoanotherlevel.png';

const Logo = () => {
  return <Image src={logo} alt="logoAnotherLevel" width="64px" height="64px" />;
};
const ListHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
      {children}
    </Text>
  );
};

export default function LargeWithLogoLeft() {
  return (
    <Box
      mt="20"
      bg={useColorModeValue('gray.50', 'gray.900')}
      color={useColorModeValue('gray.700', 'gray.200')}
    >
      <Container as={Stack} maxW={'6xl'} py={10}>
        <SimpleGrid
          templateColumns={{ sm: '1fr 1fr', md: '2fr 1fr 1fr 1fr 1fr' }}
          spacing={8}
        >
          <Link href="https://www.anotherlevel.it">
            <Stack spacing={6}>
              <Box>
                <Logo />
              </Box>
              <Text fontSize={'sm'}>
                © 2023 Studio casserà sito sviluppato da AnotherLevel
              </Text>
            </Stack>
          </Link>
          <Stack align={'flex-start'}>
            <ListHeader>Servizi</ListHeader>
            <Link href={'#'}>Contabilità Azinde</Link>
            <Link href={'#'}>Dichiarazione Redditi</Link>
            <Link href={'#'}>Finanziamenti a privati, a imprese</Link>
            <Link href={'#'}>Cessioni del quinto, mutui</Link>
            <Link href={'#'}>Pratiche ipocatastali</Link>
            <Link href={'#'}>Trascrizione atti</Link>
            <Link href={'#'}>Successioni</Link>
            <Link href={'#'}>Apertura Partite Iva</Link>
            <Link href={'#'}>Pratiche Suape</Link>
            <Link href={'#'}>Iscrizione CCIAA, Inps, Inail</Link>
          </Stack>
          <Stack align={'flex-start'}>
            <ListHeader>Contattaci</ListHeader>
            <Link href={'#'}>Telefono</Link>
            <Link href={'#'}>Email</Link>
          </Stack>
        </SimpleGrid>
      </Container>
    </Box>
  );
}
