import React from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  extendTheme,
  useColorMode,
  Button,
  Container,
  Code,
  Grid,
  Image,
  theme,
  Stack,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import { FaSun, FaMoon } from 'react-icons/fa';
import Features from './components/features.tsx';
import Footer from './components/footer.tsx';
import { Global, css } from '@emotion/react';
import LandingLogo from './landinglogo.png';

const myTheme = extendTheme({
  fonts: {
    body: 'Roboto, sans-serif',
    heading: 'Roboto, sans-serif',
    mono: 'Menlo, monospace',
  },
});

const robotoFont = css`
  @import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
`;

function App() {
  return (
    <ChakraProvider theme={myTheme}>
      <Global styles={robotoFont} />

      <Home />
      <Features />
      <Footer />
    </ChakraProvider>
  );
}

function Home() {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <>
      <Box p={4}>
        <Stack alignItems={'end'}>
          <Box m="3">
            <Button onClick={toggleColorMode}>
              {colorMode === 'light' ? <FaMoon /> : <FaSun />}
            </Button>
          </Box>
        </Stack>
        <Stack
          as={Container}
          maxW={'4xl'}
          justifyContent={'center'}
          alignItems={'center'}
          direction={{ base: 'column', md: 'row' }}
        >
          <Box>
            <Logo />
            <Box>
              <Text fontSize={'xl'}>
                dove la nostra esperienza e professionalità sono al servizio
                delle tue esigenze contabili e finanziarie.
              </Text>
            </Box>
          </Box>

          <Image
            src={LandingLogo}
            width={{ base: '100%', md: '50%' }}
            height={{ base: '100%', md: '50%' }}
            alt="Studio Casserá illustration"
          />
        </Stack>
      </Box>
    </>
  );
}

function Logo() {
  return (
    <>
      <Box fontSize={{ base: '4xl', lg: '7xl' }} fontWeight={'semibold'}>
        <Text textColor={'messenger.500'}>
          STUDIO <Text as="span">CASSERÁ</Text>
        </Text>
      </Box>
    </>
  );
}

export default App;
